import {register} from 'shared/helpers/rollbar';

export default register({
  hostSafeList: ['dcvxs6ggqztsa.cloudfront.net', 'workable.com', 'officedroid.com'],
  ignoreRules: [
    {
      matchers: [/Request failed with status code 429/g, /Loading chunk/g, /ResizeObserver loop/g],
      path: 'rollbarPayload.body.trace.exception.message'
    }
  ]
});
