import get from 'lodash/get';

const ruleMatches = (matcher, value = '') => {
  if (matcher instanceof RegExp) {
    return matcher.test(value);
  }

  if (value instanceof Error) {
    value = value.toString();
  }

  return value.includes(matcher);
};

/*
 * `customIgnore` is used to further extend the list of ignored errors from Rollbar.
 * It is an agnostic helper function that given a set of rules, the args passed to
 * Rollbar log function, and the payload that will be sent to Rollbar API, tests
 * each rule against these values and returns `true` if at least one of the rules
 * is satisfied.
 *
 * type Rule = {path: string; matchers: Array<String | RegExp>;}
 */
export const customIgnore = (rules, rollbarArgs = [], rollbarPayload = {}) => {
  const matchAgainst = {
    rollbarArgs,
    rollbarPayload
  };

  for (const rule of rules) {
    const value = get(matchAgainst, rule.path);
    for (const matcher of rule.matchers) {
      if (ruleMatches(matcher, value)) {
        return true;
      }
    }
  }

  return false;
};
